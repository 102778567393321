import $ from 'jquery'
import 'select2/dist/css/select2.css'
import 'select2'

// $('body').on('DOMNodeInserted', 'select', function () {
//     $(this).select2();
// });
$( document ).on('turbolinks:load', function() {
  var arr = {}
  $(document).on('select2:open', e => {
    const id = e.target.id;
    const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
    target.focus();
  });

    // $("#stock_order_id").select2()

  function ts_select2_employees(elem){
    elem.select2({
      ajax: {
        url: "/human_resource/employees/employee_name_livesearch",
        method: "GET",
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            employee_name: params.term,
            employee_eligibility: elem.attr("data-eligibility")
          };
        },

        processResults: function (data, params) {
          return {
            results: data.data
          };
        },
        cache: true
      },

      placeholder: 'Select an Employee',
      minimumInputLength: 1,
      templateResult: formatRepo,
      templateSelection: formatRepoSelection
    });
  }


  $(document).on('click', '.add_fields', function(){
    console.log('add fields clicked')

    var elems = $(".employee_name_search")

    elems.each(function(){
      if(!$(this).hasClass('select2-hidden-accessible')){
        ts_select2_employees($(this));
      }
    })

  });


  ts_select2_employees($('.employee_name_search'));

  function formatRepo (repo) {
    if (repo.loading) {
      return repo.text;
    }

    var $container = $(
      "<div class='select2-account clearfix'>" +
        "<div class='select2-employee_name'></div>" +
      "</div>"
    );

    $container.find(".select2-employee_name").text(repo.employee_name);
    arr[repo.id] = repo
    return $container;
  }

  function formatRepoSelection (repo) {
    console.log("repo", repo.id)
    if (arr[repo.id]) {
      console.log("employee_salary", arr[repo.id]["employee_salary"])
      localStorage.setItem("employee_salary", arr[repo.id]["employee_salary"])
    }
    return repo.text;
  }

});
